<template>
  <div class="home">
    <a-row class="title-text">
      <a-col class="text-col">
        <h1 class="animate__animated animate__bounceInRight">WE DO BETTER</h1>
        <h2 class="animate__animated animate__bounceInRight">
          数字化工厂解决方案
        </h2>
        <button class="animate__animated animate__bounceInRight btn1" @click="showtypicalCase"></button>
      </a-col>
    </a-row>

    <div class="introduction">
      <div class="left-img">
        <img src="../assets/image/building.png" />
      </div>
      <div class="animate__animated animate__fadeInRightBig right-content">
        <div class="right-content-item">
          <h1>公司介绍</h1>
          <div class="item">
            <ul>
              <li class="first-item">
                <label>6<span>家分支结构</span></label>
                <div>
                  <p>上海达译机电工程有限公司</p>
                  <p>陕西达译机电工程有限公司</p>
                  <p>武汉达译自动化工程有限公司</p>
                  <p>北京骏观数据科技有限公司</p>
                  <p>呼和浩特分公司</p>
                  <p>青岛达译智能化科技有限公司</p>
                </div>
              </li>
              <li>
                <label>10<span>+</span></label>
                <p>工厂自动化和数字化系统研发，实施和交付经验</p>
              </li>
              <li>
                <label>70<span>+</span></label>
                <p>
                  70+长期从事工业生产领域系统软硬件卡覅，调试的行业专家、能力专家一级市场人员
                </p>
              </li>
              <li>
                <label>10<span>+</span></label>
                <p>呼和浩特项目交付中心</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <h2>业务介绍</h2>
      <a-row class="a-row" type="flex" justify="space-between">
        <a-col class="col-list" :span="7" :xs="24" :lg="7" v-for="item in contentList" :key="item.id"
          v-bind:class="{ isactive: item.active }" @click="goDetail(item.id)">
          <img class="img-l" :src="item.imgL" />
          <div class="img-r"></div>
          <h3 class="to-h3">{{ item.toH }}</h3>
          <p class="to-p">{{ item.toP }}</p>
          <!-- <button type="button" class="btn1" @click="goprodCenter">快速查看</button> -->
        </a-col>
      </a-row>
    </div>

    <!-- 成功案例 -->
    <a-row class="success">
      <a-col :span="8" class="l-box">
        <h2>成功案例</h2>

        <h3>工厂级室内CCR集中操作监控与管理</h3>
        <!--        <p class="h2-text">-->
        <!--          无论任何应用<span class="text-sty">任何屏幕角落</span>，都能轻松驾驭-->
        <!--        </p>-->
        <p class="h3-text">
          武汉CCR控制中心，结合生产管理，操作控制，数据分析，视频监控等功能，打造集成中央智控中心；
          在满足功能性需求基础之上，结合精酿文化，品牌传播，参观展示等，充分弘扬品牌价值和艺术文化；
        </p>
        <button type="button" @click="goExamine">快速查看</button>
        <!-- <div class="bottom-box">
          <div class="item1"></div>
          <div class="item1"></div>
          <div class="item1"></div>
          <div class="item1"></div>
        </div> -->
      </a-col>
      <a-col class="r-box" :span="16">
        <img src="../assets/image/CCR-1.png" class="successDemo" />
      </a-col>
    </a-row>

    <!-- 联系我们 -->
    <a-row class="call-us">
      <a-col :span="12" class="call-us-l">
        <vue-map class="vue-map"></vue-map>
      </a-col>
      <a-col :span="12" class="call-us-r">
        <div class="contactUs">
          <h2>联系我们</h2>
          <ul>
            <li v-for="item in callUsList" :key="item.id">
              <img :src="item.callIcon" />
              <span>{{ item.callPhone }}</span>
            </li>
          </ul>
        </div>
      </a-col>
    </a-row>

    <!-- 客户评价 -->
    <!-- <div class="evaluation">
      <h2>客户评价</h2>
      <a-row class="row" type="flex" justify="space-between">
        <a-col v-for="item in evaluationList" :key="item.id" class="col-list" :span="7">
          <span>{{ item.text }}</span>
          <div class="len"></div>
          <div class="head-portrait">
            <img :src="item.headImg" alt="" />
            <div class="text">
              <h3>{{ item.name }}</h3>
              <p>
                {{ item.position }}
              </p>
            </div>
          </div>
        </a-col>
      </a-row>
    </div> -->
  </div>
</template>

<script>
import Footer from "./Footer";
import VueMap from "../views/VueMap";
export default {
  name: "Home",
  components: {
    Footer,
    VueMap,
  },
  data() {
    return {
      // 业务介绍数据
      contentList: [
        {
          id: 1,
          imgL: require("../assets/image/蒙版组 95.png"),
          toH: "生产控制系统软件",
          toP: "咨询服务针对我们所熟悉的行业食品、啤酒、饮料等提供从建造、采购、工艺、自动化等范围内的专业咨询服务，科根据客户的不同需求，指定不同的咨询方案。",
        },
        {
          id: 2,
          imgL: require("../assets/image/蒙版组 96.png"),
          toH: "数字化生产管理平台",
          toP: "精酿啤酒工程将自动化和信息化植入精酿啤酒行业，用最专业的只是将精酿啤酒发挥的淋淋尽致，这就是我们一直追求的目标。",
        },
        {
          id: 3,
          imgL: require("../assets/image/蒙版组 97.png"),
          toH: "硬件系统工程服务",
          toP: "咨询服务针对我们所熟悉的行业食品、啤酒、饮料等提供从建造、采购、工艺、自动化等范围内的专业咨询服务，科根据客户的不同需求，指定不同的咨询方案。",
        },
      ],

      // 联系我们数据
      callUsList: [
        {
          callId: 1,
          callIcon: require("../assets/image/蒙版组 102.png"),
          callPhone: "客服电话：021-54580601",
        },
        {
          callId: 2,
          callIcon: require("../assets/image/蒙版组 101.png"),
          callPhone: "Sales@keytech-engineering.com",
        },
        {
          callId: 3,
          callIcon: require("../assets/image/蒙版组 100.png"),
          callPhone: "上海达译机电工程有限公司",
        },
        {
          callId: 4,
          callIcon: require("../assets/image/蒙版组 99.png"),
          callPhone: "武汉达译自动化工程有限公司",
        },
        {
          callId: 5,
          callIcon: require("../assets/image/蒙版组 98.png"),
          callPhone: "武汉市东西湖区环湖中路当代硅谷电子产业园 12栋1单元",
        },
      ],

      // 客户评价数据
      // evaluationList: [
      //   {
      //     id: 1,
      //     text: '" 自系统上线运行以来，即使在大数据量存储和更新的前提下，达译的数据库系统依然保持稳定性和高效性，得到了用户的高度认可。',
      //     headImg: require("../assets/image/蒙版组 14.png"),
      //     name: "陈xx",
      //     position: "xxxxxx科技管理公司项目经理",
      //   },
      //   {
      //     id: 2,
      //     text: '" 自系统上线运行以来，即使在大数据量存储和更新的前提下，达译的数据库系统依然保持稳定性和高效性，得到了用户的高度认可。',
      //     headImg: require("../assets/image/蒙版组 14.png"),
      //     name: "陈xx",
      //     position: "xxxxxx科技管理公司项目经理",
      //   },
      //   {
      //     id: 3,
      //     text: '" 自系统上线运行以来，即使在大数据量存储和更新的前提下，达译的数据库系统依然保持稳定性和高效性，得到了用户的高度认可。',
      //     headImg: require("../assets/image/蒙版组 14.png"),
      //     name: "陈xx",
      //     position: "xxxxxx科技管理公司项目经理",
      //   },
      // ],
    };
  },
  mounted() {
    this.goDetail(1);
    // console.log(document.documentElement.style.fontSize);
    console.log(this.$router);
  },
  methods: {
    goDetail(id) {
      this.contentList.map((s) => {
        s.active = s.id === id ? true : false;
        return s;
      });
      this.contentList = [...this.contentList];
    },

    goprodCenter() {
      this.$router.push({ name: "prodCenter" });
    },

    // 快速查看
    goExamine() {
      this.$router.push({ name: "demoDetails" });
    },
    showtypicalCase() {
      this.$router.push({ name: "typicalCase" });
    },
  },
};
</script>
<style lang="less">
.home {
  width: 100%;
  position: relative;

  .title-text {
    transition: all 0.2s ease-out;
    position: absolute;
    left: 12.5%;
    height: 200px;
    top: -880px;

    h1 {
      font-size: 105px;
      color: #ffffff;
      font-weight: Regular;
    }

    h2 {
      font-size: 40px;
      color: #ffffff;
      font-weight: Regular;
      margin-top: 22px;
    }

    .btn1 {
      width: 200px;
      height: 50px;

      background: url("../assets/image/787.png") no-repeat;
      background-size: 200px 50px;
      border-radius: 10px;
      border: 1px solid #ff854b;
      transition: 2s opacity ease;

      &:active {
        opacity: 0.6;
      }
    }
  }

  .introduction {
    display: flex;
    flex-direction: row;
    margin-top: 180px;
    // align-items: flex-end;
    align-items: center;

    .left-img {
      flex: 1;
      text-align: right;
      overflow: hidden;

      img {
        // width: 920px;
        // height: 600px;
        width: 100%;
        height: 100%;

        cursor: pointer;
        transition: 1s transform ease;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .right-content {
      flex: 1;
      // height: 600px;
      display: flex;
      align-items: center;

      .right-content-item {
        width: 720px;
        border-radius: 0 10px 10px 0;
        padding: 27px;
        background-image: linear-gradient(300deg,
            #ff8f4d 20%,
            #ffa003 20.05%,
            #ff912e 20.1%,
            #ff912e 35%,
            #ffaa03 35.05%,
            #ffae29 35.1%,
            #fe842a 75%,
            #ffaa03 75.05%,
            #ff7d2c 75.4%,
            #ff7d2c 90%,
            #ffaa03 90.05%,
            #ff7a26 90.25%,
            #ff7a26);

        h1 {
          font-size: 40px;
          color: #ffffff;
        }

        .item {
          ul {
            padding-left: 17px;
            margin-top: 38px;

            li {
              bottom: 0px;
              display: flex;
              color: #ffffff;
              width: 100%;
              align-items: center;

              >p {
                margin: 0px;
                margin-left: 26px;
                width: 80%;
                // font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
              }

              label {
                width: 20%;
                margin-bottom: 0px;
                font-size: 40px;
                font-family: DIN;
                font-weight: 500;
              }

              span {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                margin-left: 3px;
              }
            }

            .first-item div {
              width: 80%;
              display: flex;
              flex-wrap: wrap;
              font-size: 12px;
              margin-left: 26px;

              p {
                width: 45%;

                &:nth-child(even) {
                  margin-left: 20px;
                }

                &::before {
                  content: "";
                  display: block;
                  border: solid 5px #ffffff;
                  width: 0px;
                  border-top: solid 5px transparent;
                  border-bottom: solid 5px transparent;
                  border-right: none;
                  float: left;
                  margin-top: 6px;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }

  .int-row {
    transition: all 0.2s ease-out;
    margin-top: 182px;

    .int-col {
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
      background: url("../assets/image/792.png") no-repeat;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;

      .list-r {
        padding: 0px;
        // height: 540px;
        background-size: 100% 100%;
        margin-top: 40px;
        padding: 47px;
        box-sizing: border-box;

        h1 {
          font-size: 40px;
          color: #ffffff;
          margin-left: 11px;
          margin-top: 24px;
        }

        .item {
          ul {
            padding-left: 17px;
            margin-top: 38px;

            li {
              display: flex;
              color: #ffffff;
              width: 100%;
              // height: 80px;
              align-items: center;

              >p {
                margin: 0px;
                margin-left: 26px;
                width: 80%;
                font-size: 16px;
                font-weight: Normal;
                font-family: Source Han Sans CN;
              }

              label {
                width: 20%;
                margin-bottom: 0px;
                font-size: 40px;
                font-family: DIN;
                font-weight: 500;
              }

              span {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                margin-left: 3px;
              }
            }

            .first-item div {
              width: 80%;
              display: flex;
              flex-wrap: wrap;
              margin-left: 26px;

              p {
                width: 45%;

                &:nth-child(even) {
                  margin-left: 20px;
                }

                &::before {
                  content: "";
                  display: block;
                  border: solid 5px #ffffff;
                  width: 0px;
                  border-top: solid 5px transparent;
                  border-bottom: solid 5px transparent;
                  border-right: none;
                  float: left;
                  margin-top: 6px;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }

  .content {
    transition: all 0.2s ease-in-out;
    width: 75%;
    margin: 0 auto;
    margin-top: 140px;

    h2 {
      text-align: center;
      font-size: 40px;
      font-family: Source Han Sans CN;
      font-weight: bold;
    }

    .a-row {
      .col-list {
        border-radius: 10px;
        transition: all 0.4s linear;
        box-shadow: 0px 10px 30px rgba(23, 35, 55, 0.1);
        background: rgba(255, 255, 255, 0.39);
        padding: 35px;
        position: relative;

        &:hover {
          .to-h3 {
            color: #ffffff;
            transition: all 0.4s ease-in-out;
          }

          background: linear-gradient(135deg, #ff854b, #e85500);
          color: #ffffff;
          box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
          transition: all 0.4s ease-in-out;

          .img-r {
            background-image: url("../assets/image/28841.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }

        .img-r {
          position: absolute;
          right: 0;
          top: 0;
          width: 100px;
          height: 140px;
          background-image: url("../assets/image/28841-red.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          //  z-index: 99;
        }

        .to-h3 {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          margin-top: 48px;
          margin-bottom: 30px;
        }

        .to-p {
          text-indent: 32px;
          margin-bottom: 50px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
        }

        .btn1 {
          width: 200px;
          padding: 10px;
          background: rgba(255, 255, 255, 0.39);
          border-radius: 10px;
          border: 1px solid #e85500;
          font-size: 18px;
          position: absolute;
          bottom: 25px;
          cursor: pointer;

          &:active {
            opacity: 0.5;
          }
        }
      }
    }
  }

  .success {
    // max-width: 1680px;
    width: 75%;
    height: 550px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15);
    // margin: 0 auto;
    margin-left: 240px;
    margin-top: 140px;
    display: flex;
    align-items: center;
    transition: all 0.2s ease-out;

    .l-box {
      padding: 30px 35px;

      .h2-txt {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
      }

      p {
        color: #777777;

        .text-sty {
          // margin-top: 20px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ff854b;
        }
      }

      h2 {
        // margin-bottom: 20px;
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: bold;
      }

      h3 {
        // margin-top: 85px;
        // margin-bottom: 30px;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: bold;
      }

      .h3-text {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
      }

      button {
        // margin-top: 40px;
        width: 200px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.39);
        border-radius: 10px;
        border: 1px solid #e85500;
        color: #e85500;
        bottom: 15px;
        transition: all 0.2s ease;
        cursor: pointer;

        &:active {
          background-color: #e85500;
          color: #ffffff;
          opacity: 0.5;
        }
      }

      .bottom-box {
        margin-top: 20px;

        .item1 {
          width: 20px;
          height: 6px;
          background-color: #000000;
          opacity: 0.3;
          border-radius: 4px;
          float: left;
          margin-right: 5px;
          transition: all 0.4s linear;

          &:hover {
            width: 45px;
            height: 6px;
            opacity: 1;
            background-color: #000000;
          }
        }
      }
    }

    .r-box {
      height: 100%;
      overflow: hidden;

      .successDemo {
        width: 100%;
        height: 100%;
        transition: 0.6s transform ease;

        &:hover {
          cursor: pointer;
          transform: scale(1.1);
        }
      }
    }
  }

  .call-us {
    width: 75%;
    height: 500px;
    margin: 0 auto;
    margin-top: 140px;
    transition: all 0.2s ease-out;
    display: flex;
    align-items: center;

    .call-us-l {
      height: 100%;
    }

    .call-us-r {
      padding-left: 35px;

      // height: 100%;
      .contactUs {
        h2 {
          font-size: 40px;
          font-family: Source Han Sans CN;
          font-weight: bold;
        }

        ul {
          li {
            margin-top: 20px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
          }
        }
      }
    }
  }

  .evaluation {
    width: 75%;
    margin: 140px auto;
    transition: all 0.2s ease-out;

    h2 {
      text-align: center;
      font-size: 40px;
      font-family: Source Han Sans CN;
      font-weight: bold;
    }

    .row {
      .col-list {
        padding: 25px;
        border: 2px solid #ff6e29;
        border-top-right-radius: 30px;
        border-bottom-left-radius: 30px;

        .len {
          width: 100%;
          height: 3px;
          background-color: #d3d4dc;
          margin-top: 30px;
          margin-bottom: 20px;
        }

        .head-portrait {
          display: flex;
          justify-content: space-around;
          align-items: flex-end;

          img {
            width: 70px;
            height: 70px;
          }

          .text {
            h3 {
              height: 26px;
              font-size: 26px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              line-height: 48px;
              color: #0e0c1b;
            }

            p {
              margin: 0;
              font-size: 20px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #0e0c1b;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 700px) {
    .title-text {
      position: absolute;
      left: 12.5%;
      top: -800px;
      height: 200px;

      h1 {
        font-size: 125px;
      }

      h2 {
        font-size: 80px;
        margin-top: 10px;
      }

      .btn1 {
        width: 450px;
        height: 150px;

        background: url("../assets/image/787.png") no-repeat;
        background-size: 450px 150px;
        border-radius: 10px;
        border: 1px solid #ff854b;
        transition: 2s opacity ease;

        &:active {
          opacity: 0.6;
        }
      }
    }

    .introduction {
      .left-img {
        display: none;
      }

      .right-content {
        .right-content-item {
          width: 100%;
          border-radius: 10px;
          padding: 47px;
          background-image: linear-gradient(300deg,
              #ff8f4d 20%,
              #ffa003 20.05%,
              #ff912e 20.1%,
              #ff912e 35%,
              #ffaa03 35.05%,
              #ffae29 35.1%,
              #fe842a 75%,
              #ffaa03 75.05%,
              #ff7d2c 75.4%,
              #ff7d2c 90%,
              #ffaa03 90.05%,
              #ff7a26 90.25%,
              #ff7a26);

          h1 {
            font-size: 80px;
            color: #ffffff;
          }

          .item {
            ul {
              padding-left: 17px;
              margin-top: 38px;

              li {
                bottom: 0px;
                display: flex;
                color: #ffffff;
                width: 100%;
                align-items: center;

                >p {
                  margin: 0px;
                  margin-left: 26px;
                  width: 80%;
                  font-size: 60px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                }

                label {
                  width: 20%;
                  margin-bottom: 0px;
                  font-size: 120px;
                  font-family: DIN;
                  font-weight: 500;
                }

                span {
                  font-size: 42px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  margin-left: 3px;
                }
              }

              .first-item div {
                width: 80%;
                display: flex;
                flex-wrap: wrap;
                font-size: 12px;
                margin-left: 26px;

                p {
                  width: 45%;
                  font-size: 60px;

                  &:nth-child(even) {
                    margin-left: 20px;
                  }

                  &::before {
                    content: "";
                    display: block;
                    border: solid 30px #ffffff;
                    width: 0px;
                    border-top: solid 30px transparent;
                    border-bottom: solid 30px transparent;
                    border-right: none;
                    float: left;
                    margin-top: 6px;
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .content {
      transition: all 0.2s ease-in-out;
      width: 75%;
      margin: 0 auto;
      margin-top: 140px;

      h2 {
        text-align: center;
        font-size: 90px;
        font-family: Source Han Sans CN;
        font-weight: bold;
      }

      .a-row {
        .col-list {
          // height: 1200px;
          border-radius: 10px;
          transition: all 0.4s linear;
          box-shadow: 0px 10px 30px rgba(23, 35, 55, 0.1);
          background: rgba(255, 255, 255, 0.39);
          padding: 35px;
          margin-bottom: 50px;
          position: relative;

          overflow: hidden;
          // white-space: nowrap;
          text-overflow: ellipsis;

          &:hover {
            .to-h3 {
              color: #ffffff;
              transition: all 0.4s ease-in-out;
            }

            background: linear-gradient(135deg, #ff854b, #e85500);
            color: #ffffff;
            box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
            transition: all 0.4s ease-in-out;

            .img-r {
              background-image: url("../assets/image/28841.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }
          }

          .img-l {
            // display: none;
            width: 100px;
            height: 100px;
          }

          .img-r {
            position: absolute;
            right: 0;
            top: 0;
            width: 100px;
            height: 140px;
            background-image: url("../assets/image/28841-red.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            //  z-index: 99;
          }

          .to-h3 {
            font-size: 62px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            margin-top: 18px;
            margin-bottom: 30px;
          }

          .to-p {
            text-indent: 96px;
            margin-bottom: 50px;
            font-size: 48px;
            font-family: Source Han Sans CN;
            font-weight: 400;
          }

          .btn1 {
            width: 200px;
            padding: 10px;
            background: rgba(255, 255, 255, 0.39);
            border-radius: 10px;
            border: 1px solid #e85500;
            font-size: 18px;
            position: absolute;
            bottom: 25px;
            cursor: pointer;

            &:active {
              opacity: 0.5;
            }
          }
        }
      }
    }

    .success {
      display: none;
    }

    .call-us {
      width: 75%;
      height: 1000px;
      margin: 0 auto;
      margin-top: 140px;
      transition: all 0.2s ease-out;
      display: flex;
      align-items: center;

      .call-us-l {
        height: 100%;
      }

      .call-us-r {
        padding-left: 45px;

        // height: 100%;
        .contactUs {
          h2 {
            font-size: 80px;
            font-family: Source Han Sans CN;
            font-weight: bold;
          }

          ul {
            li {
              margin-top: 20px;
              font-size: 40px;
              font-family: Source Han Sans CN;
              font-weight: 800;
            }
          }
        }
      }
    }
  }
}
</style>
