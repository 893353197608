<template>
  <div class="footer">
<!--    <a-row style="margin-bottom: 10px">-->
<!--      <img style="width: 160px" src="../assets/image/16.png" alt="">-->
<!--    </a-row>-->
    <a-row type="flex" justify="space-between">
      <a-col class="second" span="6">
        <h3>快速导航</h3>
        <ul>
          <li v-for="item in menuList" :key="item.id" @click="show(item)">{{ item.name }}</li>
        </ul>
      </a-col>
      <a-col class="first" span="6">
        <h3>关注我们</h3>
        <img src="../assets/image/图像 13.png" alt="">
      </a-col>
      <a-col class="third" span="12">
        <h3>联系我们</h3>
        <div class="contact">
          <span>邮箱：</span>
          <h4>fang.cheng@keytech-engineering.com</h4>
        </div>
        <ul>
          <li>
            <b>武汉市：</b>
            <span>武汉市东西湖区环湖中路当代硅谷电子产业园 12 栋 1 单元 1号电梯3 层</span>
          </li>
          <li>
            <b>呼和浩特市：</b>
            <span>呼和浩特市玉泉区南二环路 理想加 713</span>
          </li>
        </ul>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        { id: 0, name: " > 首页", url: "home" },
        { id: 1, name: " > 关于我们", url: "about" },
        // { id: 2, name: '产品中心', url: 'prodCenter' },
        { id: 3, name: " > 典型案例", url: "typicalCase" },
        // { id: 4, name: '新闻中心', url: 'newsCenter' },
        // { id: 5, name: '人才发展', url: 'talentDevelopment' },
        { id: 6, name: " > 招聘中心", url: "recruitmentCenter" }
      ]
    };
  },
  methods: {
    show(item) {
      this.$router.push({ name: item.url });
    }
  }
};
</script>

<style lang="less">
.footer {
  width: 100%;
  background: url("../assets/image/792.png") no-repeat;
  margin-top: 50px;
  font-size: 18px;
  padding: 80px 342px;
  color: #ffffff;
  h3 {
    position: relative;

  }
  h3::before {
    position: absolute;
    bottom: 0;
    left: 35px;
    transform: translateX(-50%);
    content: '';
    width: 60px;
    height: 3px;
    border-radius: 5px;
    background-color: #ccc;
    color: #fff;
  }
  .first {
    h3 {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
    }

    img {
      width: 107px;
      margin-top: 28px;
      margin-bottom: 0px;
    }
  }

  .second {
    h3 {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
    }

    ul {
      margin-top: 30px;

      li {
        margin-top: 5px;
        cursor: pointer;
      }
    }
  }

  .third {
    h3 {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
    }

    ul {
      li {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        b {
          width: 140px;
        }
        span {
          width: 420px;
        }
      }
    }

    .contact {

      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      span {
        width: 140px;
      }
      h4 {
        width: 420px;
        font-style:oblique;
        font-size: 24px;
        color: #a93b00;
      }
    }
  }
}
</style>
